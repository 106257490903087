<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsButton, KsPopover } from '../../lib/js'

  const options = reactive({
    direction: 'down right',
    autoposition: true,
    demo: 'Menu',
    demos: ['Menu', 'Infobox']
  })
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsPopover
        v-if="options.demo === 'Menu'"
        :title="options.demo"
        :direction="options.direction"
        :autoposition="options.autoposition"
      >
        <template #toggle>
          <KsButton variant="primary"> Open popover </KsButton>
        </template>
        <template #content>
          <KsButton stretch> Open </KsButton>
          <KsButton
            icon-left="share"
            href="#"
            stretch
            @click.prevent="() => {}"
          >
            Share
          </KsButton>
          <KsButton
            icon-left="trash"
            stretch
          >
            Delete
          </KsButton>
          <KsButton
            icon-left="carrot"
            stretch
            disabled
          >
            Disabled
          </KsButton>
        </template>
      </KsPopover>
      <KsPopover
        v-if="options.demo === 'Infobox'"
        :direction="options.direction"
        :title="options.demo"
      >
        <template #toggle>
          <KsButton
            variant="secondary"
            shape="rounded"
          >
            {{ 'Open popover' }}
          </KsButton>
        </template>
        <template #content>
          <div class="docs-popover-infobox">
            <img
              src="/images/logo-aco.png"
              alt=""
            />
            <p>
              Besøk vårt læringsunivers, <a href="/">Aschehoug univers</a>. Med
              læreren. For eleven.
            </p>
          </div>
        </template>
      </KsPopover>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Demo</legend>
          <select v-model="options.demo">
            <option
              v-for="demoOption in options.demos"
              :key="demoOption"
              :value="demoOption"
              v-text="demoOption"
            />
          </select>
        </fieldset>
        <fieldset>
          <legend>Direction</legend>
          <label>
            Down left
            <input
              v-model="options.direction"
              class="ks-input"
              name="direction"
              type="radio"
              value="down left"
            />
          </label>
          <label>
            Down right
            <input
              v-model="options.direction"
              class="ks-input"
              name="direction"
              type="radio"
              value="down right"
            />
          </label>
          <label>
            Up left
            <input
              v-model="options.direction"
              class="ks-input"
              name="direction"
              type="radio"
              value="up left"
            />
          </label>
          <label>
            Up right
            <input
              v-model="options.direction"
              class="ks-input"
              name="direction"
              type="radio"
              value="up right"
            />
          </label>
        </fieldset>
        <label>
          Autoposition
          <input
            v-model="options.autoposition"
            class="ks-input"
            type="checkbox"
          />
        </label>
      </div>
    </template>
  </KsDocsComponent>
</template>

<style scoped>
  .docs-popover-infobox {
    width: 200px;
    padding: 0.5em;
  }

  .docs-popover-infobox img {
    width: 6em;
  }
  .docs-popover-infobox p {
    margin-top: 1em;
    line-height: 1.3;
    font-size: 0.8em;
  }

  .docs-popover-infobox a {
    text-decoration: underline;
  }
</style>
