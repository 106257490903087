import KsToast from './KsToast.vue'
import { h, createApp, App } from 'vue'

type Options = {
  variant?: string
  message?: string
  target?: string
  duration?: number
  persistent?: boolean
  buttons?: { text: string; onClick: () => void }[]
  onClose?: () => void
}

const TOAST_GAP = 25

function notify(options: Options) {
  // Set up options with default values
  options.variant = options.variant || 'info'
  options.message = options.message || '&nbsp;'
  options.target = options.target || 'body'
  options.duration = Math.max(0, Number(options.duration)) || 2000
  options.persistent = Boolean(options.persistent) || false
  options.onClose = options.onClose || (() => {})

  const target = document.querySelector(options.target)
  if (!target) return

  const Toast = h(
    KsToast,
    {
      variant: options.variant,
      persistent: options.persistent,
      buttons: options.buttons,
      onClose: () => {
        remove(toast)
        options.onClose?.()
      }
    },
    () => options.message
  )

  // Live region to contain all toasts
  let region = target.querySelector('.ks-toast-region')
  if (!region) {
    region = document.createElement('div')
    region.classList.add('ks-toast-region')
    region.setAttribute('role', 'status')
    region.setAttribute('aria-live', 'polite')
    region.setAttribute('aria-atomic', 'false')
    region.setAttribute('aria-relevant', 'additions')
    target.insertAdjacentElement('afterbegin', region)
  }
  const allToasts = Array.from(region.querySelectorAll('.ks-toast-wrapper'))
  const allHeight = allToasts
    .map((t) => t.getBoundingClientRect().height)
    .reduce((t1, t2) => t1 + t2 + TOAST_GAP, 0)
  const toast = document.createElement('aside')
  toast.classList.add('ks-toast-wrapper')
  createApp(Toast).mount(toast)
  region.insertAdjacentElement('afterbegin', toast)
  toast.style.bottom = `${allHeight + TOAST_GAP}px`
  toast.firstElementChild!.setAttribute('hidden', 'true')

  window.setTimeout(
    () => toast.firstElementChild!.removeAttribute('hidden'),
    200
  )
  if (options.persistent) return
  window.setTimeout(() => remove(toast), options.duration)
}

function remove(toast: HTMLElement) {
  if (!toast) return
  const toasts = document.querySelectorAll<HTMLElement>('.ks-toast-wrapper')
  for (const otherToast of Array.from(toasts)) {
    if (otherToast !== toast) {
      const thisHeight = toast.getBoundingClientRect().height
      const otherBottom = +window
        .getComputedStyle(otherToast)
        .bottom.replace('px', '')
      otherToast.style.bottom = `${otherBottom - thisHeight - TOAST_GAP}px`
      otherToast.style.transition = '.3s'
    } else {
      toast.setAttribute('hidden', 'true')
      window.setTimeout(() => {
        toast.remove()
      }, 400)
      break
    }
  }
}

function toObject(options: Options | string) {
  return typeof options === 'string' ? { message: options } : options
}

function ksToast(globalOptions: Options) {
  return {
    info(options: Options | string) {
      notify(
        Object.assign({ ...globalOptions }, toObject(options), {
          variant: 'info'
        })
      )
    },
    success(options: Options | string) {
      notify(
        Object.assign({ ...globalOptions }, toObject(options), {
          variant: 'success'
        })
      )
    },
    error(options: Options | string) {
      notify(
        Object.assign({ ...globalOptions }, toObject(options), {
          variant: 'error'
        })
      )
    }
  }
}

export default {
  install(app: App, options = {}) {
    app.provide('ksToast', ksToast(options))
  }
}
