<script setup lang="ts">
  import { ref, computed, inject, ComputedRef } from 'vue'
  import { KsButton } from '.'
  import type { Expand } from './KsExpand.vue'
  import { uid } from './utils'

  const props = withDefaults(
    defineProps<{
      title?: string
      level?: string
      shape?: string
      disabled?: boolean
    }>(),
    {
      title: '',
      level: 'h2',
      shape: 'normal',
      disabled: false
    }
  )

  const hidden = ref(true)
  const button = ref()
  const content = ref()
  const item = ref()
  const uuid = computed(() => uid())
  const expand = inject<ComputedRef<Expand>>('ks-expand')

  function onKeyDown({ key }: KeyboardEvent) {
    const buttons = item.value.parentElement.querySelectorAll(
      '.ks-expand-toggle > .ks-expand-button:not(:disabled)'
    )
    const index = Array.from(buttons).indexOf(button.value.el)
    let nextButton
    if (key === 'ArrowUp') nextButton = buttons[index - 1]
    if (key === 'ArrowDown') nextButton = buttons[index + 1]
    if (key === 'Home') nextButton = buttons[0]
    if (key === 'End') nextButton = buttons[buttons.length - 1]
    nextButton?.focus()
  }

  function onToggle() {
    const openButton = item.value.parentElement.querySelector(
      '[aria-expanded="true"]'
    )
    if (!expand?.value.multiple && openButton !== button.value.el) {
      openButton?.click()
    }
    hidden.value = !hidden.value
    const height = content.value.scrollHeight // Store current state and force repaint
    content.value.style.height = hidden.value ? `${height}px` : '0px'
    content.value.offsetHeight // eslint-disable-line no-unused-expressions
    content.value.style.height = hidden.value ? '0px' : `${height}px`
    setTimeout(() => {
      content.value.style.height = ''
      content.value.style.overflow = ''
    }, 260)
  }

  defineExpose({ el: item })
</script>

<template>
  <div
    ref="item"
    class="ks-expand-item"
  >
    <component
      :is="props.level"
      class="ks-expand-toggle"
    >
      <span v-if="$slots.toggle">
        <slot name="toggle" />
      </span>
      <KsButton
        ref="button"
        class="ks-expand-button"
        :class="{
          'ks-expand-button-inline': $slots.toggle
        }"
        variant="secondary"
        :shape="props.shape"
        :stretch="!$slots.toggle"
        icon-right="chevron-down"
        :aria-expanded="!hidden ? 'true' : 'false'"
        :aria-controls="`ks-expand-content-${uuid}`"
        :disabled="disabled"
        @click="onToggle"
        @keydown.up.down.home.end.prevent="onKeyDown"
      >
        <slot
          name="prefix"
          :open="!hidden"
        />
        <span v-text="props.title" />
      </KsButton>
    </component>
    <div
      ref="content"
      :id="`ks-expand-content-${uuid}`"
      class="ks-expand-content"
      role="group"
      :hidden="hidden"
    >
      <div>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .ks-expand-toggle {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: inherit;
    margin: 0;
  }

  .ks-expand-button {
    padding-right: 3em !important;
    font-size: inherit !important;
    min-width: auto !important;
    height: auto !important;
  }

  .ks-expand-button > span {
    text-align: left;
    white-space: pre-wrap;
  }

  .ks-expand-button-inline {
    position: static;
  }

  .ks-expand-button :deep(svg) {
    position: absolute;
    right: 1em;
    transition: 0.2s;
  }

  .ks-expand-button[aria-expanded='true'] :deep(svg) {
    transform: rotate(180deg);
  }

  .ks-expand-content {
    overflow: hidden;
    transition: height 0.2s;
  }

  .ks-expand-content[hidden] {
    display: block;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }

  .ks-expand-content > div {
    margin: 0;
    padding: 1em 1em 0;
    font-size: 1rem;
  }
</style>
