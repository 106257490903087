<script setup lang="ts">
  import { ref, watch, onMounted, onBeforeUnmount, defineOptions } from 'vue'
  import { FOCUSABLE } from './utils'
  import { KsButton } from '.'

  defineOptions({
    inheritAttrs: false
  })

  const props = withDefaults(
    defineProps<{
      title: string
      strict?: boolean
      open?: boolean
      size?: string
      backdrop?: boolean
      dismissable?: boolean
    }>(),
    {
      strict: false,
      open: false,
      size: 'small',
      backdrop: true,
      dismissable: true
    }
  )

  const emit = defineEmits<{
    (event: 'close'): void
    (event: 'close:outside'): void
  }>()

  let opener: HTMLElement | null
  let clickedOutside = false
  let resizeObserver: ResizeObserver | null = null // Checking overflow
  const overflows = ref(false)
  const opened = ref(false)
  const dialog = ref()
  const body = ref()
  const FOCUS_DELAY = 250 // Focus after CSS transition

  function onResize(entries: ResizeObserverEntry[]) {
    const entry = entries[0]
    if (!props.open || !dialog.value || !entry) return
    overflows.value = dialog.value.scrollHeight > dialog.value.clientHeight
  }

  function onMousedown() {
    if (props.strict) return
    clickedOutside = true
    setTimeout(() => {
      clickedOutside = false
    }, 500) // Register only short clicks
  }

  function onMouseup() {
    if (props.strict) return
    if (clickedOutside) {
      emit('close')
      emit('close:outside')
    }
    clickedOutside = false
  }

  function onFocusout(event: FocusEvent) {
    const target = event.target as HTMLElement
    const relatedTarget = event.relatedTarget as HTMLElement | null

    // Not open or focusing next dialog, skip focusing
    if (
      !props.open ||
      !relatedTarget ||
      relatedTarget.classList.contains('ks-dialog')
    ) {
      return
    }

    let el = relatedTarget as HTMLElement | null
    for (; el && el !== document.body; el = el.parentElement) {
      if (el === dialog.value) return // Focusing inside self, skip
    }

    const focusables = Array.from<HTMLElement>(
      dialog.value.querySelectorAll(FOCUSABLE)
    ).filter((el) => !el.closest('[hidden]'))
    const tabbingForward = focusables.indexOf(target) === focusables.length - 1
    const tabTarget = focusables[tabbingForward ? 0 : focusables.length - 1]

    if (tabTarget !== target)
      tabTarget.focus() // Set focus back to top/bottom element
    else setTimeout(() => tabTarget.focus(), 0) // Keep focus on close button
  }

  onMounted(() => {
    opened.value = props.open // Override with open prop
    if (props.open) setTimeout(() => dialog.value?.focus(), FOCUS_DELAY)
    if (window.ResizeObserver) {
      resizeObserver = new ResizeObserver(onResize) // Check overflow on body content
      resizeObserver.observe(body.value, { box: 'border-box' })
    }
    if (dialog.value) {
      overflows.value = dialog.value.scrollHeight > dialog.value.clientHeight
    }
  })

  onBeforeUnmount(() => {
    if (resizeObserver) resizeObserver.unobserve(dialog.value)
  })

  watch(
    () => props.open,
    async () => {
      if (!opener) opener = document.activeElement as HTMLElement
      if (props.open) {
        setTimeout(() => dialog.value?.focus(), FOCUS_DELAY) // Focus this dialog
      } else {
        setTimeout(() => opener?.focus(), FOCUS_DELAY) // Return focus to opener
      }
      opened.value = true
    }
  )

  defineExpose({ el: dialog })
</script>

<template>
  <div
    class="ks-dialog-backdrop"
    :class="!backdrop && 'ks-dialog-backdrop-hide'"
    :hidden="!open"
    @mousedown.self="onMousedown"
    @mouseup.self="onMouseup"
  >
    <div
      ref="dialog"
      class="ks-dialog"
      :class="[
        `ks-dialog-${size}`,
        overflows && 'ks-dialog-overflows',
        $attrs.class
      ]"
      role="dialog"
      :hidden="!open"
      tabindex="-1"
      :aria-label="title"
      @focusout="onFocusout"
      @keyup.esc.stop="emit('close')"
    >
      <header class="ks-dialog-header">
        <slot name="header" />
        <h1
          v-if="title"
          v-text="title"
          class="ks-truncate"
        />
        <KsButton
          v-if="dismissable"
          class="ks-dialog-close"
          shape="round"
          variant="tertiary"
          icon-left="xmark"
          aria-label="Lukk dialogvindu"
          title="Lukk dialogvindu"
          @click="emit('close')"
        />
      </header>
      <div
        class="ks-dialog-body"
        ref="body"
      >
        <slot
          v-if="opened"
          name="body"
        />
      </div>
      <footer
        v-if="$slots.footer"
        class="ks-dialog-footer"
      >
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<style scoped>
  .ks-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 70;
    transition: 0.2s;
  }

  .ks-dialog-backdrop[hidden] {
    display: block;
    visibility: hidden;
    opacity: 0;
  }

  .ks-dialog-backdrop-hide {
    background: transparent;
  }

  .ks-dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform ease 0.3s;
    max-height: 92vh;
    margin: auto;
    color: var(--ks-text);
    background: var(--ks-surface);
    border-radius: calc(20px * var(--ks-roundness));
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    overscroll-behavior: contain;
  }

  .ks-dialog[hidden] {
    display: block;
    transform: translateY(-40%);
  }

  .ks-dialog-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1.6rem 1.6rem 0;
  }

  .ks-dialog-header h1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
  }

  .ks-dialog-body {
    padding: 1.6rem;
    height: 100%;
  }

  .ks-dialog-footer {
    padding: 1.6rem;
    gap: 1rem;
    background: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .ks-dialog-overflows {
    overflow: auto;
  }

  .ks-dialog-overflows .ks-dialog-footer {
    position: sticky;
    bottom: 0;
    border-top: 1px solid lightgray;
    z-index: 10;
  }

  .ks-dialog-small {
    max-width: 550px;
  }

  .ks-dialog-medium {
    max-width: 750px;
  }

  .ks-dialog-large {
    max-width: 1140px;
  }
</style>
