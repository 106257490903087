<script setup lang="ts">
  import { computed, nextTick, ref, watch } from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  type IconVariant = 'regular' | 'solid' | 'light' | 'sharp-regular'

  const props = withDefaults(
    defineProps<{
      icon: string
      scale?: number
      variant?: IconVariant
      animationDuration?: string
      loopAnimation?: boolean
      bounce?: boolean
      shake?: boolean
      beat?: boolean
      fade?: boolean
      spin?: boolean
      beatFade?: boolean
      spinPulse?: boolean
      spinReverse?: boolean
    }>(),
    {
      scale: 1.0,
      variant: 'regular',
      animationDuration: '1000ms',
      loopAnimation: false,
      bounce: false,
      shake: false,
      beat: false,
      fade: false,
      spin: false,
      beatFade: false,
      spinPulse: false,
      spinReverse: false
    }
  )

  const getIcon: Record<IconVariant, (icon: string) => string> = {
    regular: (icon) => `fa-regular fa-${icon}`,
    solid: (icon) => `fa-solid fa-${icon}`,
    light: (icon) => `fa-light fa-${icon}`,
    'sharp-regular': (icon) => `fa-sharp fa-regular fa-${icon}`
  }

  const iconRef = ref()
  const rect = ref({ width: 20, height: 20 })
  const renderIcon = ref(true)

  const width = computed(() => `${(rect.value.width / 16) * props.scale}em`)
  const height = computed(() => `${(rect.value.height / 16) * props.scale}em`)
  const iconToRender = computed(() =>
    getIcon[props.variant as IconVariant](props.icon)
  )
  const animationIterationCount = computed(() =>
    props.loopAnimation ? 'infinite' : '1'
  )

  // watch for prop changes to re-render icon with new styles dynamically
  watch(props, async () => {
    renderIcon.value = false
    await nextTick()
    renderIcon.value = true
  })

  defineExpose({ el: iconRef })
</script>

<template>
  <FontAwesomeIcon
    v-if="renderIcon"
    ref="iconRef"
    :style="{ width, height }"
    :icon="iconToRender"
    :bounce="bounce"
    :shake="shake"
    :beat="beat"
    :fade="fade"
    :spin="spin"
    :beat-fade="beatFade"
    :spin-pulse="spinPulse"
    :spin-reverse="spinReverse"
  />
</template>

<style scoped>
  svg {
    --fa-animation-duration: v-bind('props.animationDuration');
    --fa-animation-iteration-count: v-bind(animationIterationCount);
    pointer-events: none;
  }
</style>
