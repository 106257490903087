<script setup lang="ts">
  import { KsIcon } from '.'
  import { ref } from 'vue'

  withDefaults(
    defineProps<{
      variant?: string
      shape?: string
      size?: string
      iconRight?: string
      iconLeft?: string
      href?: string
      stretch?: boolean
      center?: boolean
      raised?: boolean
    }>(),
    {
      variant: 'tertiary',
      shape: 'normal',
      size: 'medium',
      iconRight: '',
      iconLeft: '',
      href: '',
      stretch: false,
      center: false,
      raised: false
    }
  )

  const button = ref()

  defineExpose({ el: button })
</script>

<template>
  <component
    ref="button"
    :is="href ? 'a' : $attrs.type === 'label' ? 'label' : 'button'"
    class="ks-button"
    :class="[
      `ks-button-${variant}`,
      `ks-button-${size}`,
      `ks-button-${shape}`,
      center && 'ks-button-center',
      stretch && 'ks-button-stretch',
      raised && 'ks-button-raised'
    ]"
    :href="href"
    type="button"
  >
    <KsIcon
      v-if="iconLeft"
      :icon="iconLeft"
    />
    <slot />
    <KsIcon
      v-if="iconRight"
      :icon="iconRight"
    />
  </component>
</template>

<style scoped>
  .ks-button {
    appearance: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    flex-shrink: 0;
    height: 2.6em;
    padding: 1em 1.4em;
    font: inherit;
    cursor: pointer;
    user-select: none;
    border: 0;
    font-weight: 500;
    text-decoration: none;
    transition: 0.2s;
    white-space: nowrap;
    box-shadow: none;
  }

  .ks-button:is(:disabled, [disabled='true']) {
    filter: grayscale(100%);
    opacity: 0.7;
    cursor: not-allowed;
  }

  .ks-button:focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
  }

  .ks-button-primary {
    color: var(--ks-primarytext);
    background: var(--ks-primary);
  }

  .ks-button-primary:not(:disabled, [disabled='true']):hover {
    background: var(--ks-secondary);
  }

  .ks-button-secondary {
    color: var(--ks-secondary);
    background: var(--ks-input);
  }

  .ks-button-secondary:not(:disabled, [disabled='true']):hover {
    background: var(--ks-inputhover);
  }

  .ks-button-tertiary {
    color: var(--ks-secondary);
    background: transparent;
  }

  .ks-button-tertiary:not(:disabled, [disabled='true']):hover {
    background: var(--ks-input);
  }

  .ks-button-border {
    background: transparent;
    border: 2px solid var(--ks-border);
  }

  .ks-button-border:not(:disabled, [disabled='true']):hover {
    background: var(--ks-borderhoverfill);
    color: var(--ks-borderhovertext);
  }

  .ks-button-small {
    font-size: 0.8rem;
  }

  .ks-button-medium {
    font-size: 1rem;
  }

  .ks-button-large {
    font-size: 1.2rem;
  }

  .ks-button-normal {
    min-width: 4.2em;
    border-radius: calc(12px * var(--ks-roundness));
  }

  .ks-button-rounded {
    border-radius: 50px;
  }

  .ks-button-round {
    justify-content: center;
    width: 2.6em;
    border-radius: 50px;
    padding-left: 0;
    padding-right: 0;
  }

  .ks-button-square {
    justify-content: center;
    width: 2.6em;
    border-radius: calc(12px * var(--ks-roundness));
    padding-left: 0;
    padding-right: 0;
  }

  .ks-button-stretch {
    width: 100%;
    min-width: max-content;
  }

  .ks-button-center {
    justify-content: center;
  }

  .ks-button-raised {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);
    z-index: 20;
  }

  .ks-button-raised:not(:disabled, [disabled='true']):hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
  }
</style>
