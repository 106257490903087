<script setup lang="ts">
  import { ref } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsButton } from '../../lib/js'

  const direction = ref('down')
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <div>
        <KsButton
          v-if="direction === 'down'"
          v-ks-tooltip="'Settings'"
          variant="secondary"
          icon-left="cog"
          shape="square"
          size="large"
        />
        <KsButton
          v-if="direction === 'up'"
          v-ks-tooltip.up="'Settings'"
          variant="secondary"
          icon-left="cog"
          shape="square"
          size="large"
        />
        <KsButton
          v-if="direction === 'left'"
          v-ks-tooltip.left="'Settings'"
          variant="secondary"
          icon-left="cog"
          shape="square"
          size="large"
        />
        <KsButton
          v-if="direction === 'right'"
          v-ks-tooltip.right="'Settings'"
          variant="secondary"
          icon-left="cog"
          shape="square"
          size="large"
        />
      </div>
    </template>
    <template #controls>
      <fieldset>
        <legend>Direction</legend>
        <label>
          Down
          <input
            v-model="direction"
            class="ks-input"
            type="radio"
            name="direction"
            value="down"
          />
        </label>
        <label>
          Up
          <input
            v-model="direction"
            class="ks-input"
            type="radio"
            name="direction"
            value="up"
          />
        </label>
        <label>
          Left
          <input
            v-model="direction"
            class="ks-input"
            type="radio"
            name="direction"
            value="left"
          />
        </label>
        <label>
          Right
          <input
            v-model="direction"
            class="ks-input"
            type="radio"
            name="direction"
            value="right"
          />
        </label>
      </fieldset>
    </template>
  </KsDocsComponent>
</template>
