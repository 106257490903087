import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBoxArchive as farFaBoxArchive,
  faArrowLeft as farFaArrowLeft,
  faArrowRight as farFaArrowRight,
  faArrowLeftLong as farFaArrowLeftLong,
  faArrowRightLong as farFaArrowRightLong,
  faArrowUpRight as farFaArrowUpRight,
  faArrowUpRightFromSquare as farFaArrowUpRightFromSquare,
  faArrowsRotate as farFaArrowsRotate,
  faVolume as farFaVolume,
  faVolumeXmark as farFaVolumeXmark,
  faBackpack as farFaBackpack,
  faBagShopping as farFaBagShopping,
  faBars as farFaBars,
  faBell as farFaBell,
  faBook as farFaBook,
  faBooks as farFaBooks,
  faBrowser as farFaBrowser,
  faCalendar as farFaCalendar,
  faCalendarRange as farFaCalendarRange,
  faCalendarCirclePlus as farFaCalendarCirclePlus,
  faCalendarPlus as farFaCalendarPlus,
  faCalendarDay as farFaCalendarDay,
  faCamera as farFaCamera,
  faCaretUp as farFaCaretUp,
  faCaretDown as farFaCaretDown,
  faComments as farFaComments,
  faCheck as farFaCheck,
  faCheckCircle as farFaCheckCircle,
  faChevronDown as farFaChevronDown,
  faChevronLeft as farFaChevronLeft,
  faChevronRight as farFaChevronRight,
  faChevronUp as farFaChevronUp,
  faClock as farFaClock,
  faCog as farFaCog,
  faRectangleVerticalHistory as farFaRectangleVerticalHistory,
  faCookie as farFaCookie,
  faCopy as farFaCopy,
  faCopyright as farFaCopyright,
  faDownload as farFaDownload,
  faEar as farFaEar,
  faEarthEurope as farFaEarthEurope,
  faEllipsis as farFaEllipsis,
  faEllipsisVertical as farFaEllipsisVertical,
  faEnvelope as farFaEnvelope,
  faEye as farFaEye,
  faEyeSlash as farFaEyeSlash,
  faFaceSmile as farFaFaceSmile,
  faFileCheck as farFaFileCheck,
  faFloppyDisk as farFaFloppyDisk,
  faGingerbreadMan as farFaGingerbreadMan,
  faGlasses as farFaGlasses,
  faGrid as farFaGrid,
  faGrid2 as farFaGrid2,
  faHeadphones as farFaHeadphones,
  faHeart as farFaHeart,
  faHexagon as farFaHexagon,
  faHouse as farFaHouse,
  faHouseBlank as farFaHouseBlank,
  faInfo as farFaInfo,
  faInfoCircle as farFaInfoCircle,
  faLampDesk as farFaLampDesk,
  faLayerGroup as farFaLayerGroup,
  faLeaf as farFaLeaf,
  faLifeRing as farFaLifeRing,
  faLink as farFaLink,
  faList as farFaList,
  faLocationPin as farFaLocationPin,
  faLock as farFaLock,
  faArrowRightFromBracket as farFaArrowRightFromBracket,
  faMagnifyingGlass as farFaMagnifyingGlass,
  faPhotoFilm as farFaPhotoFilm,
  faMinus as farFaMinus,
  faMusic as farFaMusic,
  faNewspaper as farFaNewspaper,
  faPause as farFaPause,
  faPencil as farFaPencil,
  faPencilSquare as farFaPencilSquare,
  faPersonChalkboard as farFaPersonChalkboard,
  faPlay as farFaPlay,
  faPlus as farFaPlus,
  faPrint as farFaPrint,
  faQuestion as farFaQuestion,
  faQuestionCircle as farFaQuestionCircle,
  faReceipt as farFaReceipt,
  faSeedling as farFaSeedling,
  faShare as farFaShare,
  faShieldCheck as farFaShieldCheck,
  faSliders as farFaSliders,
  faSlidersUp as farFaSlidersUp,
  faStars as farFaStars,
  faText as farFaText,
  faThoughtBubble as farFaThoughtBubble,
  faTrash as farFaTrash,
  faTrashXmark as farFaTrashXmark,
  faTriangleExclamation as farFaTriangleExclamation,
  faUniversalAccess as farFaUniversalAccess,
  faUser as farFaUser,
  faUsers as farFaUsers,
  faVideo as farFaVideo,
  faWaveform as farFaWaveform,
  faXmark as farFaXmark,
  faXmarkCircle as farFaXmarkCircle,
  faFaceSunglasses as farFaFaceSunglasses,
  faWavePulse as farFaWavePulse,
  faChildReaching as farFaChildReaching,
  faMaximize as farFaMaximize,
  faMinimize as farFaMinimize,
  faExpand as farFaExpand,
  faCompress as farFaCompress
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  farFaBoxArchive,
  farFaArrowLeft,
  farFaArrowRight,
  farFaArrowLeftLong,
  farFaArrowRightLong,
  farFaArrowUpRight,
  farFaArrowUpRightFromSquare,
  farFaArrowsRotate,
  farFaVolume,
  farFaVolumeXmark,
  farFaBackpack,
  farFaBagShopping,
  farFaBars,
  farFaBell,
  farFaBook,
  farFaBooks,
  farFaBrowser,
  farFaCalendar,
  farFaCalendarRange,
  farFaCalendarCirclePlus,
  farFaCalendarPlus,
  farFaCalendarDay,
  farFaCamera,
  farFaCaretUp,
  farFaCaretDown,
  farFaComments,
  farFaCheck,
  farFaCheckCircle,
  farFaChevronDown,
  farFaChevronLeft,
  farFaChevronRight,
  farFaChevronUp,
  farFaClock,
  farFaCog,
  farFaRectangleVerticalHistory,
  farFaCookie,
  farFaCopy,
  farFaCopyright,
  farFaDownload,
  farFaEar,
  farFaEarthEurope,
  farFaEllipsis,
  farFaEllipsisVertical,
  farFaEnvelope,
  farFaEye,
  farFaEyeSlash,
  farFaFaceSmile,
  farFaFileCheck,
  farFaFloppyDisk,
  farFaGingerbreadMan,
  farFaGlasses,
  farFaGrid,
  farFaGrid2,
  farFaHeadphones,
  farFaHeart,
  farFaHexagon,
  farFaHouse,
  farFaHouseBlank,
  farFaInfo,
  farFaInfoCircle,
  farFaLampDesk,
  farFaLayerGroup,
  farFaLeaf,
  farFaLifeRing,
  farFaLink,
  farFaList,
  farFaLocationPin,
  farFaLock,
  farFaArrowRightFromBracket,
  farFaMagnifyingGlass,
  farFaPhotoFilm,
  farFaMinus,
  farFaMusic,
  farFaNewspaper,
  farFaPause,
  farFaPencil,
  farFaPencilSquare,
  farFaPersonChalkboard,
  farFaPlay,
  farFaPlus,
  farFaPrint,
  farFaQuestion,
  farFaQuestionCircle,
  farFaReceipt,
  farFaSeedling,
  farFaShare,
  farFaShieldCheck,
  farFaSliders,
  farFaSlidersUp,
  farFaStars,
  farFaText,
  farFaThoughtBubble,
  farFaTrash,
  farFaTrashXmark,
  farFaTriangleExclamation,
  farFaUniversalAccess,
  farFaUser,
  farFaUsers,
  farFaVideo,
  farFaWaveform,
  farFaXmark,
  farFaXmarkCircle,
  farFaFaceSunglasses,
  farFaWavePulse,
  farFaChildReaching,
  farFaMaximize,
  farFaMinimize,
  farFaExpand,
  farFaCompress
)
