<script setup lang="ts">
  import { ref } from 'vue'
  import { KsButton, KsIcon } from '.'

  withDefaults(
    defineProps<{
      variant: string
      title?: string
      dismissable?: boolean
    }>(),
    {
      title: '',
      dismissable: false
    }
  )

  const emit = defineEmits<{
    (event: 'close'): void
  }>()

  const open = ref(true)
  const callout = ref()

  const icon: any = {
    info: 'info-circle',
    success: 'check-circle',
    warning: 'exclamation-triangle',
    error: 'xmark-circle'
  }

  const label: any = {
    info: 'Melding, info',
    success: 'Melding, suksess',
    warning: 'Melding, advarsel',
    error: 'Melding, feil'
  }

  function onClose({ target }: MouseEvent) {
    const button = target as HTMLElement
    const callout = button.parentNode as HTMLElement
    const nextElement = callout.nextElementSibling as HTMLElement | null
    open.value = false
    emit('close')
    nextElement?.focus()
  }

  defineExpose({ el: callout })
</script>

<template>
  <aside
    ref="callout"
    v-if="open"
    :aria-label="label[variant]"
    class="ks-callout"
    :class="[`ks-callout-${variant}`, dismissable && 'ks-callout-dismissable']"
  >
    <KsIcon
      class="ks-callout-icon"
      :icon="icon[variant]"
    />
    <div class="ks-callout-content">
      <h2
        v-if="title"
        class="ks-callout-title"
        v-text="title"
      />
      <slot />
    </div>
    <KsButton
      v-if="dismissable"
      class="ks-callout-button"
      variant="tertiary"
      size="small"
      shape="square"
      icon-left="xmark"
      aria-label="Lukk melding"
      title="Lukk melding"
      @click="onClose"
    />
  </aside>
</template>

<style scoped>
  .ks-callout {
    position: relative;
    width: 100%;
    color: black;
    padding: 1.3rem;
    border-radius: calc(12px * var(--ks-roundness));
    text-align: left;
  }

  .ks-callout-icon {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    font-size: 1.3rem;
    margin-top: 2px;
    margin-right: 0.7em;
  }

  .ks-callout-button {
    position: absolute;
    top: 1.2em;
    right: 1.2em;
  }

  .ks-callout-title {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0 0 0.2em;
  }

  .ks-callout-content {
    font-size: 1rem;
    padding: 0 1rem 0 2.2rem;
  }

  .ks-callout-info {
    background: var(--ks-infosurface);
  }

  .ks-callout-info .ks-callout-icon {
    color: var(--ks-info);
  }

  .ks-callout-success {
    background: var(--ks-successurface);
  }

  .ks-callout-success .ks-callout-icon {
    color: var(--ks-success);
  }

  .ks-callout-warning {
    background: var(--ks-warningsurface);
  }

  .ks-callout-warning .ks-callout-icon {
    color: var(--ks-warning);
  }

  .ks-callout-error {
    background: var(--ks-errorsurface);
  }

  .ks-callout-error .ks-callout-icon {
    color: var(--ks-error);
  }

  .ks-callout-dismissable .ks-callout-content {
    padding-right: 1.8rem;
  }
</style>
