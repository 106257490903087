<script setup lang="ts">
  import { reactive, computed, watch } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsButton } from '../../lib/js'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  const options = reactive({
    text: 'Button',
    variant: 'primary',
    size: 'medium',
    shape: 'normal',
    icon: false,
    disabled: false,
    stretch: false,
    center: false,
    raised: false
  })

  const textMaxlength = computed(() => {
    if (isSquare()) {
      if (options.icon) return 0
      return 2
    }
    return 25
  })

  const textDisabled = computed(() => {
    return isSquare() && options.icon
  })

  function isSquare() {
    return ['square', 'round'].includes(options.shape)
  }

  function constrainInput() {
    if (isSquare()) {
      if (options.icon) {
        options.text = ''
      } else {
        options.text = '42'
      }
    } else {
      options.text = 'Button'
    }
  }

  watch(
    () => [options.shape, options.icon],
    () => constrainInput()
  )
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsButton
        :icon-left="options.icon ? 'magnifying-glass' : ''"
        :variant="options.variant"
        :shape="options.shape"
        :size="options.size"
        :disabled="options.disabled"
        :stretch="options.stretch"
        :center="options.center"
        :raised="options.raised"
      >
        {{ options.text }}
      </KsButton>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Variant</legend>
          <label>
            Primary
            <input
              v-model="options.variant"
              class="ks-input"
              type="radio"
              name="variant"
              value="primary"
            />
          </label>
          <label>
            Secondary
            <input
              v-model="options.variant"
              class="ks-input"
              type="radio"
              name="variant"
              value="secondary"
            />
          </label>
          <label>
            Tertiary
            <input
              v-model="options.variant"
              class="ks-input"
              type="radio"
              name="variant"
              value="tertiary"
            />
          </label>
          <label>
            Border
            <input
              v-model="options.variant"
              class="ks-input"
              type="radio"
              name="variant"
              value="border"
            />
          </label>
        </fieldset>
        <fieldset>
          <legend>Size</legend>
          <label>
            Small
            <input
              v-model="options.size"
              class="ks-input"
              type="radio"
              name="size"
              value="small"
            />
          </label>
          <label>
            Medium
            <input
              v-model="options.size"
              class="ks-input"
              type="radio"
              name="size"
              value="medium"
            />
          </label>
          <label>
            Large
            <input
              v-model="options.size"
              class="ks-input"
              type="radio"
              name="size"
              value="large"
            />
          </label>
        </fieldset>
      </div>
      <div>
        <fieldset>
          <legend>Shape</legend>
          <label>
            Normal
            <input
              v-model="options.shape"
              class="ks-input"
              type="radio"
              name="shape"
              value="normal"
            />
          </label>
          <label>
            Square
            <input
              v-model="options.shape"
              class="ks-input"
              type="radio"
              name="shape"
              value="square"
            />
          </label>
          <label>
            Rounded
            <input
              v-model="options.shape"
              class="ks-input"
              type="radio"
              name="shape"
              value="rounded"
            />
          </label>
          <label>
            Round
            <input
              v-model="options.shape"
              class="ks-input"
              type="radio"
              name="shape"
              value="round"
            />
          </label>
        </fieldset>
        <label>
          Stretch
          <input
            v-model="options.stretch"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Center
          <input
            v-model="options.center"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Icon
          <input
            v-model="options.icon"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Raised
          <input
            v-model="options.raised"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Disabled
          <input
            v-model="options.disabled"
            class="ks-input"
            type="checkbox"
          />
        </label>
      </div>
      <div>
        <fieldset :disabled="textDisabled">
          <legend>Text</legend>
          <input
            v-model="options.text"
            type="text"
            placeholder="..."
            :maxlength="textMaxlength"
            @keydown.enter.prevent=""
          />
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>
