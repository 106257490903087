<script setup lang="ts">
  import { ref, computed, onMounted, watch } from 'vue'
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { KsInput, KsIcon, KsDropdown, KsButton } from '../../lib/js'

  type IconVariant = 'regular' | 'solid' | 'light' | 'sharp-regular'

  const iconPrefixes: Record<IconVariant, string> = {
    regular: 'far',
    solid: 'fas',
    light: 'fal',
    'sharp-regular': 'fasr'
  }

  const variants: IconVariant[] = ['regular', 'solid', 'light', 'sharp-regular']
  const ANIMATION_DURATION = '2000ms'

  const faIcons = ref<string[][]>([])
  const searchQuery = ref('')
  const variant = ref<IconVariant>()
  const animate = ref(false)

  const filteredIcons = computed(() => {
    return faIcons.value?.filter((icon) =>
      icon[0].toLowerCase().includes(searchQuery.value.toLowerCase())
    )
  })

  async function copyToClipboard(event: any, icon: string, message: string) {
    const button = event.target
    await navigator.clipboard.writeText(icon)
    const buttonText = button.innerText
    button.innerText = message
    await new Promise((resolve) => setTimeout(resolve, 2000))
    button.innerText = buttonText
  }

  async function copyHTML(event: any, icon: string) {
    const vue = `<KsIcon icon="${icon}" variant="${variant.value}" />`
    await copyToClipboard(event, vue, 'HTML copied!')
  }

  async function copyID(event: any, icon: string) {
    await copyToClipboard(event, icon, 'ID copied!')
  }

  function shake() {
    animate.value = true
    setTimeout(() => (animate.value = false), 1000)
  }

  onMounted(() => (variant.value = 'regular'))

  watch(variant, () => {
    faIcons.value = Object.keys(
      library.definitions[iconPrefixes[variant.value as IconVariant]]
    ).map((key) => [
      key,
      library.definitions[iconPrefixes[variant.value as IconVariant]][key]
    ])
  })
</script>

<template>
  <div class="docs-icons-search">
    <div class="docs-icons-input">
      <label for="search">Search:</label>
      <KsInput
        id="search"
        v-model="searchQuery"
        type="text"
        placeholder="Search for an icon..."
      />
    </div>
    <label class="docs-icons-input">
      Variant:
      <KsDropdown
        v-model="variant"
        :options="variants"
        :option-label="(option: string) => option.charAt(0).toUpperCase() + option.slice(1)"
        :deselectable="() => false"
        placeholder="Choose variant"
      />
    </label>
    <KsButton
      shape="square"
      variant="secondary"
      icon-left="face-sunglasses"
      @click="shake"
    />
  </div>
  <ul class="docs-icons-list">
    <li
      v-for="icon in filteredIcons"
      :key="icon[0]"
      class="ks-truncate"
    >
      <div class="docs-icon">
        <KsIcon
          :icon="icon[0]"
          :variant="variant"
          :shake="animate"
          :animation-duration="ANIMATION_DURATION"
        />
        <div class="docs-icon-buttons">
          <button @click="copyHTML($event, icon[0])">Copy HTML</button>
          <button @click="copyID($event, icon[0])">Copy ID</button>
        </div>
      </div>
      <span v-text="icon[0]"></span>
    </li>
  </ul>
  <p v-if="filteredIcons?.length === 0">No matches found.</p>
</template>

<style scoped>
  .docs-icons-search {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
  }

  .docs-icons-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .docs-icons-input:first-child {
    width: 100%;
  }

  .docs-icons-list {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .docs-icons-list li {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 0.4rem;
    flex: 0 0 190px;
  }

  .docs-icon {
    position: relative;
    padding: 1em;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    font-size: 24px;
    color: inherit;
  }

  .docs-icon:hover .docs-icon-buttons {
    display: flex;
    flex-direction: column;
  }

  .docs-icon-buttons {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .docs-icon-buttons button {
    height: 100%;
    border-radius: 6px;
    margin: 3px;
    cursor: pointer;
    appearance: none;
    color: inherit;
    background-color: rgba(15, 23, 42, 0.08);
    border: none;
    font-size: 14px;
    font-weight: 600;
    transition: 0.2s;
  }

  .docs-icon-buttons button:hover {
    background-color: rgba(15, 23, 42, 0.2);
  }
</style>
