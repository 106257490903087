<script setup lang="ts">
  import { ref } from 'vue'
  import {
    KsButton,
    KsDialog,
    KsPopover,
    KsInput,
    KsDropdown,
    KsSpinner,
    KsTextArea,
    KsBreadcrumbs,
    KsProgress,
    KsExpand,
    KsExpandItem,
    KsTabs,
    KsTab,
    KsSwitch
  } from '../../lib/js'

  const dialogOpen = ref(false)
  const dropdownValue = ref('Apple')
  const dropdownOptions = ['Apple', 'Pear', 'Banana']
  const radioButton = ref('One')
  const password = ref('Secret')
  const breadcrumbs = [
    { title: 'Home', href: '#' },
    { title: 'Books', href: '#' },
    { title: 'Crime and Punishment', href: '#' },
    { title: 'About', href: '#' }
  ]
  const fileName = ref('Notes.docx')
</script>

<template>
  <div class="theme-preview">
    <label class="theme-preview-text">
      Text:
      <KsInput
        type="text"
        placeholder="Text..."
      />
    </label>
    <label class="theme-preview-search">
      Search:
      <KsInput
        type="search"
        placeholder="Search..."
      />
    </label>
    <label class="theme-preview-dropdown">
      Select a fruit:
      <KsDropdown
        v-model="dropdownValue"
        :options="dropdownOptions"
        :option-label="(opt) => opt"
        placeholder="Dropdown"
      />
    </label>
    <label class="theme-preview-textarea">
      Textarea:
      <KsTextArea
        placeholder="Write something..."
        rows="3"
      />
    </label>
    <label class="theme-preview-password">
      Password:
      <KsInput
        v-model="password"
        type="password"
        placeholder="Password..."
      />
    </label>
    <div class="theme-preview-buttons">
      <KsButton variant="primary"> Primary </KsButton>
      <KsButton variant="secondary"> Secondary </KsButton>
      <KsButton variant="tertiary"> Tertiary </KsButton>
      <KsButton
        v-ks-tooltip.down="'Tooltip'"
        variant="secondary"
        shape="square"
        icon-left="house"
      />
      <KsPopover
        title="Popover"
        direction="down left"
      >
        <template #toggle>
          <KsButton
            variant="primary"
            icon-right="ellipsis"
          >
            Menu
          </KsButton>
        </template>
        <template #content>
          <KsButton stretch> Open </KsButton>
          <KsButton
            icon-right="share"
            href="#"
            stretch
            @click.prevent="() => {}"
          >
            Share
          </KsButton>
          <KsButton
            icon-right="trash"
            stretch
          >
            Delete
          </KsButton>
          <KsButton
            stretch
            disabled
          >
            Disabled
          </KsButton>
        </template>
      </KsPopover>
      <KsButton
        variant="primary"
        icon-right="floppy-disk"
        @click="dialogOpen = !dialogOpen"
      >
        Save dialog
      </KsButton>
      <Teleport to=".ks-docs-top">
        <KsDialog
          title="Save"
          :open="dialogOpen"
          @close="dialogOpen = false"
        >
          <template #body>
            <div>
              <span>You have unsaved changes. Do you want to save file?</span>
              <label>
                File name
                <KsInput
                  v-model="fileName"
                  type="text"
                  placeholder="..."
                />
              </label>
            </div>
          </template>
          <template #footer>
            <div class="theme-preview-dialog-buttons">
              <KsButton
                variant="tertiary"
                @click="dialogOpen = !dialogOpen"
              >
                Cancel
              </KsButton>
              <KsButton
                variant="primary"
                icon-left="floppy-disk"
                @click="dialogOpen = !dialogOpen"
              >
                Save
              </KsButton>
            </div>
          </template>
        </KsDialog>
      </Teleport>
    </div>
    <div class="theme-preview-inputs">
      <KsInput
        type="checkbox"
        checked
      />
      <KsInput type="checkbox" />
      <KsInput
        type="radio"
        name="radio"
      />
      <KsInput
        type="radio"
        name="radio"
        checked
      />
      <KsSwitch checked />
      <KsButton
        variant="secondary"
        type="label"
      >
        <KsInput type="checkbox" />
        Subscribe
      </KsButton>
      <KsButton
        variant="secondary"
        type="label"
      >
        <KsInput
          v-model="radioButton"
          type="radio"
          name="radio-button"
          value="One"
          checked
        />
        One
      </KsButton>
      <KsButton
        variant="secondary"
        type="label"
      >
        <KsInput
          v-model="radioButton"
          type="radio"
          name="radio-button"
          value="Two"
        />
        Two
      </KsButton>
      <KsInput
        type="range"
        style="width: 150px"
      />
      <KsSpinner size="small" />
    </div>
  </div>
  <div class="theme-preview-breadcrumbs">
    <KsBreadcrumbs :items="breadcrumbs" />
    <KsProgress :values="[20]" />
  </div>
  <div class="theme-preview-expand">
    <KsExpand size="medium">
      <KsExpandItem title="1. Intro">
        <template #content> This is the intro </template>
      </KsExpandItem>
      <KsExpandItem title="2. Main">
        <template #content> This is the main part </template>
      </KsExpandItem>
      <KsExpandItem title="3. Ending">
        <template #content> This is the ending </template>
      </KsExpandItem>
    </KsExpand>
    <KsTabs>
      <KsTab title="Tab 1"> This is the tabpanel for tab 1 </KsTab>
      <KsTab
        title="Tab 2 disabled"
        disabled
      />
      <KsTab title="Tab 3"> This is the tabpanel for tab 3 </KsTab>
      <KsTab title="Tab 4"> This is the tabpanel for tab 4 </KsTab>
    </KsTabs>
  </div>
</template>

<style scoped>
  .theme-preview {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .theme-preview-text,
  .theme-preview-search,
  .theme-preview-dropdown {
    grid-column: span 4;
  }

  .theme-preview-textarea,
  .theme-preview-password {
    grid-column: span 6;
  }

  .theme-preview-buttons,
  .theme-preview-inputs {
    grid-column: span 12;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;
  }

  .theme-preview-dialog-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .theme-preview-breadcrumbs {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }

  .theme-preview-expand {
    display: flex;
    gap: 1rem;
  }

  .theme-preview-breadcrumbs > *,
  .theme-preview-expand > * {
    flex-basis: 50%;
  }
</style>
