<script setup lang="ts">
  import { reactive, inject, watch } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsButton } from '../../lib/js'

  const ksToast = inject('ksToast') as any

  const options = reactive({
    variant: 'info',
    message: 'You have 5 unread messages',
    duration: 5,
    buttons: false,
    persistent: false
  })

  function onClick() {
    const toast = {
      message: options.message,
      buttons: options.buttons ? [{ text: 'Open' }, { text: 'Ignore' }] : null,
      duration: options.duration * 1000,
      persistent: options.persistent
    }
    ksToast[options.variant](toast)
  }

  watch(
    () => options.variant,
    () => {
      if (options.variant === 'info')
        options.message = 'You have 5 unread messages'
      if (options.variant === 'success') options.message = 'Draft saved!'
      if (options.variant === 'error')
        options.message = 'Oops! Something went wrong!'
    }
  )
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <div>
        <KsButton
          variant="primary"
          icon-left="bell"
          @click="onClick"
        >
          Make toast
        </KsButton>
      </div>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Variant</legend>
          <label>
            Info
            <input
              v-model="options.variant"
              class="ks-input"
              name="variant"
              type="radio"
              value="info"
            />
          </label>
          <label>
            Success
            <input
              v-model="options.variant"
              class="ks-input"
              name="variant"
              type="radio"
              value="success"
            />
          </label>
          <label>
            Error
            <input
              v-model="options.variant"
              class="ks-input"
              name="variant"
              type="radio"
              value="error"
            />
          </label>
        </fieldset>
        <fieldset>
          <label>
            Persistent
            <input
              v-model="options.persistent"
              class="ks-input"
              type="checkbox"
            />
          </label>
          <label>
            Buttons
            <input
              v-model="options.buttons"
              class="ks-input"
              type="checkbox"
            />
          </label>
        </fieldset>
        <fieldset>
          <legend>Duration (seconds)</legend>
          <input
            v-model="options.duration"
            type="text"
            placeholder="2"
            @keydown.enter.prevent=""
          />
        </fieldset>
        <fieldset>
          <legend>Message</legend>
          <textarea
            v-model="options.message"
            rows="3"
          />
        </fieldset>
      </div>
      <div />
    </template>
  </KsDocsComponent>
</template>
