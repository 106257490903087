<script setup lang="ts">
  import { KsButton, KsIcon } from '.'

  type Button = { text: string; onClick: () => void }

  withDefaults(
    defineProps<{
      variant: string
      persistent?: boolean
      buttons?: Button[]
    }>(),
    {
      persistent: false,
      buttons: () => []
    }
  )

  const emit = defineEmits<{
    (event: 'close'): void
  }>()

  const icon: any = {
    info: 'info-circle',
    success: 'check-circle',
    error: 'xmark-circle'
  }

  function onClick(button: Button) {
    emit('close')
    if (button.onClick) button.onClick()
  }
</script>

<template>
  <div
    class="ks-toast"
    :class="[`ks-toast-${variant}`, persistent && 'ks-toast-persistent']"
  >
    <span>
      <KsIcon
        :icon="icon[variant]"
        class="ks-toast-icon"
      />
      <slot />
    </span>
    <KsButton
      v-if="persistent"
      variant="tertiary"
      shape="square"
      size="small"
      class="ks-toast-close"
      icon-left="xmark"
      aria-label="Lukk varsling"
      @click="emit('close')"
    />
    <div
      v-if="buttons?.length"
      class="ks-toast-buttons"
    >
      <KsButton
        v-for="button of buttons"
        :key="button.text"
        variant="tertiary"
        size="small"
        @click="onClick(button)"
      >
        {{ button.text }}
      </KsButton>
    </div>
  </div>
</template>

<style scoped>
  .ks-toast {
    max-width: min(100vw - 2rem, 450px);
    color: black;
    border-radius: calc(12px * var(--ks-roundness));
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-size: 1rem;
    padding: 1rem 1.2rem;
  }

  .ks-toast-info {
    background: var(--ks-infosurface);
  }

  .ks-toast-success {
    background: var(--ks-successurface);
  }

  .ks-toast-error {
    background: var(--ks-errorsurface);
  }

  .ks-toast > span {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    line-height: 1;
    font-weight: 500;
  }

  .ks-toast-persistent > span {
    margin-right: 2.2rem;
  }

  .ks-toast-icon {
    flex-shrink: 0;
    font-size: 1.2rem;
  }

  .ks-toast-info .ks-toast-icon {
    color: var(--ks-info);
  }

  .ks-toast-success .ks-toast-icon {
    color: var(--ks-success);
  }

  .ks-toast-error .ks-toast-icon {
    color: var(--ks-error);
  }

  .ks-toast-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.7rem;
  }

  .ks-toast-buttons {
    display: flex;
    gap: 0.3rem;
    margin: 0.7rem 1.2rem 0 0;
  }
</style>
